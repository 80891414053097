<template>
  <v-container class="contenedor-main">
    <v-row>
      <!-- <h5> {{ historial}}</h5> -->
      <tool-bar-nav
        :pageRow="{ value: pageLengthPer, type: 'usuariosHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'editar_usuarios',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :deleteBottom="{
          badgeText: 'Eliminar usuario',
          tipoEndpoint: 'usuario',
          endpoint: '/eliminaciones',
          atributo: 'userId',
        }"
        :copyRowBottom="{ badgeText: 'Copiar usuario' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          nameDoc: 'Usuarios',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosUsuarios($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container class="contenedor-tabla" v-show="mostrarTable == true">
        <vue-excel-editor
          ref="grid"
          v-show="mostrarTable == true"
          v-if="mt"
          @select="onSelect"
          :page="pageLengthPer"
          v-model="jsondata"
          :localized-label="myLabels"
          filter-row
          remember
          @cell-click="position"
          @cell-focus="getLabelColor"
          :enterToSouth="enterAbajo"
          v-on:toggle-table="activeRigthClick($event)"
          :cell-style="metodoColorBack"
        >
          <vue-excel-column
            field="userId"
            label="Id"
            type="string"
            :invisible="true"
          />
          <vue-excel-column
            field="Primer nombre"
            width="200px"
            label="Primer nombre"
            type="string"
            key-field
            :change="editUser"
          />
          <vue-excel-column
            field="Segundo nombre"
            width="200px"
            label="Segundo nombre"
            type="string"
            key-field
            :change="editUser"
          />
          <vue-excel-column
            field="Primer apellido"
            width="200px"
            label="Primer apellido"
            type="string"
            key-field
            :change="editUser"
          />
          <vue-excel-column
            field="Segundo apellido"
            width="200px"
            label="Segundo apellido"
            type="string"
            key-field
            :change="editUser"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            width="200px"
            :validate="valid"
            type="string"
            key-field
          />
          <vue-excel-column
            field="isAdmin"
            width="100px"
            label="Administrador"
            :change="editUser"
            type="select"
            :options="[
              'CEO',
              'Gerente',
              'Docente',
              'Coordinador',
              'SAC Clientes',
              'SAC Cartera',
            ]"
          />
        </vue-excel-editor>
      </v-container>
    </v-row>
  </v-container>
</template>


<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import ToolBarNav from "./component/ToolBarNav.vue";
import infoSelected from "./Tools/itemSelected";

import tablesMixin from "../mixins/tablas";
import data from "./Tools/data";

export default {
  mixins: [tablesMixin],
  components: {
    ToolBarNav,
  },

  data() {
    return {
      jsondata: [],
      mostrarTable: false,
      editarColumnas: false,
      columnasMostrar: [],
      listaColumnas: [],
      pageLengthPer: this.$store.state.usuariosHeigth,
      onSelected: null,
      mt: false,
      textoActionToolBar: [],
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: false,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
      opciones: [
        "CEO",
        "Gerente",
        "Docente",
        "Coordinador",
        "SAC Clientes",
        "SAC Cartera",
      ],
    };
  },

  mounted() {
    this.initialize();
  },

  async created() {
    document.title = "Usuarios";
    await this.cargueColors();
    if (this.$store.state.adminLevel == null) {
      await data.getAdminLevel().then((e) => {
        this.$store.state.adminLevel = e.isAdmin;
      });
    }
  },

  methods: {
    // changeLength(a) {
    //   console.log(this.$refs.grid["pageSize"]);
    //   this.$refs.grid["pageSize"] = parseInt(a);
    //   this.$forceUpdate();
    // },

    initialize() {
      this.jsondata = [];
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.mt = false;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/listaUsuarios", fd).then((result) => {
            let data = result.data.lista;
            for (let i = 0; i < data.length; i++) {
              switch (data[i].isAdmin) {
                case -1:
                  data[i].isAdmin = "CEO";
                  break;
                case 0:
                  data[i].isAdmin = "Coordinador";
                  break;
                case 1:
                  data[i].isAdmin = "Gerente";
                  break;
                case 2:
                  data[i].isAdmin = "Docente";
                  break;
                case -2:
                  data[i].isAdmin = "SAC Clientes";
                  break;
                case -3:
                  data[i].isAdmin = "SAC Cartera";
                  break;
                default:
                  break;
              }
            }
            this.jsondata = data;
            this.mostrarTable = true;

            if (data.length == 0) {
              this.$alert("No existen usuarios");
            } else {
              this.mostrarTable = true;
              this.listaColumnas = result.data.visibilidad;
              for (var k = 0; k < this.listaColumnas.length; k++) {
                if (this.listaColumnas[k]["valor"] == true) {
                  this.columnasMostrar.push(this.listaColumnas[k]);
                }
              }
              this.cargueCol();
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    editaCEO() {
      if (this.$store.state.adminLevel == -1) {
        return false;
      } else {
        return true;
      }
    },

    editUser(newVal, oldVal, row, field) {
      var data = field.label;
      row[data] = newVal;
      var stringNV = newVal.trim();
      var rol = null;
      if (field.label == "Administrador") {
        rol =
          stringNV == "CEO"
            ? -1
            : stringNV == "Cordinador"
            ? 0
            : stringNV == "Gerente"
            ? 1
            : stringNV == "Docente"
            ? 2
            : stringNV == "SAC Clientes"
            ? -2
            : -3;
      }
      this.textoActionToolBar.push("Guardando " + '"' + stringNV + '"');
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          // console.log('token' +idToken);
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("userId", row.userId);
          fd.append("primerNombre", row["Primer nombre"]);
          fd.append("segundoNombre", row["Segundo nombre"]);
          fd.append("primerApellido", row["Primer apellido"]);
          fd.append("segundoApellido", row["Segundo apellido"]);
          fd.append("isAdmin", rol == null ? row["Administrador"] : rol);
          fd.append("permisoAdmin", "SI");
          axios.post(host + "/editaUsuario", fd).then((result) => {
            var rem = this.textoActionToolBar.indexOf(
              "Guardando " + '"' + newVal + '"'
            );
            this.textoActionToolBar.splice(rem, 1);
            if (result.data.text != "OK") {
              this.$alert("Error! no tienes permiso para realizar esta acción");
              var noRem = this.textoActionToolBar.indexOf(
                "Guardando " + '"' + newVal + '"'
              );
              this.textoActionToolBar[noRem] =
                "Error al guardar " + '"' + newVal + '"';
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "userId");
      if (this.onSelected != null) {
        this.onSelected["mensaje"] = this.onSelected["field"]["Primer nombre"];
      }
      this.rigthClick = false;
    },

    // EVENTOS
    eventosUsuarios(d) {
      if (d == "refresh" || d == "edit") {
        this.initialize();
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },
  },
};
</script>

<style>
/* .contenedor-main {
  min-width: 100% !important;
  width: 100%;
  padding-top: 3%;
  height: 100% !important;
}
.contenedor-tabla {
  margin: 0;
  padding: 0;
  border: 1px #8884 solid;
  border-top: none;
  min-width: 100% !important;
} */
.rowNop {
  padding: 0 !important;
  margin: 0 !important;
}
.colNop {
  padding: 0;
}
</style>
