<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer
        class="navDrawerDisplay"
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        absolute
        width="400px"
      >
        <v-list-item>
          <v-btn icon @click.stop="changed(!mini)">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-list-item-content>
            <v-list-item-subtitle
              v-if="this.dataUser.primerNombre != ''"
              class="textUser"
            >
              {{
                this.isAdmin == -1
                  ? "CEO: "
                  : this.isAdmin == 2
                  ? "Docente: "
                  : this.isAdmin == 1
                  ? "Gerente: "
                  : this.isAdmin == 0
                  ? "Coordinador: "
                  : this.isAdmin == -2
                  ? "SAC Cli: "
                  : "SAC Cartera: "
              }}{{
                this.dataUser.primerNombre + " " + this.dataUser.primerApellido
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="margL">
              <v-btn dark color="primary" small @click="dialog = true"
                >Editar perfil</v-btn
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="list_item-version">
          <v-badge
            bordered
            overlap
            :value="infoV"
            color="accent"
            class="top z-top"
            content="Desarrollo by Capital Branch SAS © - 2021  "
            bottom
            transition="slide-x-transition"
          >
            <v-hover v-model="infoV">
              <v-list-item-icon>
                <h2 class="version">V:{{ version | moment("MMDD") }}</h2>
              </v-list-item-icon>
            </v-hover>
          </v-badge>

          <v-list-item-content>
            <span class="name">{{ empresa }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <!-- Calendario ???? -->
        <v-list-item
          v-show="this.isAdmin"
          v-if="this.isAdmin != 2"
          @click="changed(false)"
        >
          <v-list-item-icon>
            <v-icon @click="changed(true)">mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <nav-drawer-calendar
              v-if="notifications != []"
              v-on:toggle="toggleNoti($event)"
            ></nav-drawer-calendar>
          </v-list-item-content>
        </v-list-item>

        <v-list v-show="this.isAdmin == 0" dense nav>
          <v-list-item-group>
            <v-list-item
              v-for="item in itemsR"
              :key="item.title"
              :to="item.to"
              @click="changed(false)"
            >
              <v-list-item-icon>
                <v-icon :to="item.to" @click="changed(true)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="out()" class="margR">
              <v-list-item-icon>
                <v-icon icon @click="out()">mdi-close-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list v-show="this.isAdmin == -1" dense nav>
          <v-list-item-group>
            <v-list-item
              v-for="item in itemsSR"
              :key="item.title"
              :to="item.to"
              @click="changed(false)"
            >
              <v-list-item-icon>
                <v-icon :to="item.to" @click="changed(true)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="out()" class="margR">
              <v-list-item-icon>
                <v-icon icon @click="out()">mdi-close-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-list v-show="this.isAdmin == 2" dense nav>
          <v-list-item-group v-if="this.datosOk == true">
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.to"
              @click="changed(false)"
            >
              <v-list-item-icon>
                <v-icon :to="item.to" @click="changed(true)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="out()" class="margR">
              <v-list-item-icon>
                <v-icon icon @click="out()">mdi-close-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="out()">Cerrar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list v-show="this.isAdmin == 1" dense nav>
          <v-list-item-group v-if="this.datosOk == true">
            <v-list-item
              v-for="item in itemsA"
              :key="item.title"
              :to="item.to"
              @click="changed(false)"
            >
              <v-list-item-icon>
                <v-icon :to="item.to" @click="changed(true)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="out()" class="margR">
              <v-list-item-icon>
                <v-icon @click="out()">mdi-close-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list v-show="this.isAdmin == -2" dense nav>
          <v-list-item-group>
            <v-list-item
              v-for="item in itemsSCli"
              :key="item.title"
              :to="item.to"
              @click="changed(false)"
            >
              <v-list-item-icon>
                <v-icon :to="item.to" @click="changed(true)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="out()" class="margR">
              <v-list-item-icon>
                <v-icon icon @click="out()">mdi-close-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-list v-show="this.isAdmin == -3" dense nav>
          <v-list-item-group>
            <v-list-item
              v-for="item in itemsSC"
              :key="item.title"
              :to="item.to"
              @click="changed(false)"
            >
              <v-list-item-icon>
                <v-icon :to="item.to" @click="changed(true)">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="out()" class="margR">
              <v-list-item-icon>
                <v-icon icon @click="out()">mdi-close-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cerrar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!-- Alerta profe -->
      <v-content id="contenedor" class="contenedor" @click="changed(false)">
        <notis-admin :notifications="notifications"></notis-admin>
        <alerts-admin
          v-if="isAdmin != 2"
          :notifications="notifications"
        ></alerts-admin>
        <alerts-doc v-else> </alerts-doc>
        <router-view></router-view>
      </v-content>
      <v-dialog v-if="dialog == true" v-model="dialog" max-width="700px">
        <nav-drawer-edit-p
          :infoUser="dataUser"
          v-on:toggle="eventoPerfil($event)"
        >
        </nav-drawer-edit-p>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import data from "./Tools/data";
import NavDrawerEditP from "./component/NavDrawerEditP.vue";
import AlertsAdmin from "./component/AlertsAdmin.vue";
import NotisAdmin from "./component/NotisAdmin.vue";
import AlertsDoc from "./component/AlertsDoc.vue";
import NavDrawerCalendar from "./component/NavDrawerCalendar.vue";

export default {
  components: {
    NavDrawerCalendar,
    NavDrawerEditP,
    NotisAdmin,
    AlertsAdmin,
    AlertsDoc,
  },
  data() {
    return {
      infoV: false,
      //Ajustar para la actualización
      version: new Date(2024, 3, 30),
      empresa: "ASEU",
      attrs: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
      drawer: true,
      itemsSR: [
        {
          title: "Novedades",
          icon: "mdi-alert-decagram",
          to: "../novedadesAdmin",
        },
        { title: "IPs", icon: "mdi-view-dashboard", to: "../dashboard" },
        { title: "Alertas", icon: "mdi-bell", to: "../alertas" },
        { title: "Usuarios", icon: "mdi-account-group", to: "../usuarios" },
        {
          title: "Periodos y materias",
          icon: "mdi-calendar",
          to: "../periodos",
        },
        { title: "Cursos", icon: "mdi-book", to: "../cursosRoot" },
        {
          title: "Clientes",
          icon: "mdi-face-recognition",
          to: "../clientesAdmin",
        },
        { title: "Ventas", icon: "mdi-account-plus", to: "../clientesRoot" },
        { title: "Pagos ventas", icon: "mdi-currency-usd", to: "../pagos" },
        {
          title: "Trabajos",
          icon: "mdi-file-document-outline",
          to: "../trabajosAdmin",
        },
        {
          title: "Pagos trabajos",
          icon: "mdi-currency-usd",
          to: "../pagostrabajos",
        },
        {
          title: "Gestión",
          icon: "mdi-lightbulb-on-outline",
          to: "../gestion",
        },
        { title: "Estudiantes", icon: "mdi-account", to: "../estudiantes" },
        { title: "Listas", icon: "mdi-table-of-contents", to: "../listas" },
        {
          title: "Resumen",
          icon: "mdi-file-chart-outline",
          to: "../resumen",
        },

        {
          title: "Caja Negra",
          icon: "mdi-format-line-weight",
          to: "../cajaNegra",
        },
        // Pagos docentes
      ],
      itemsA: [
        {
          title: "Novedades",
          icon: "mdi-alert-decagram",
          to: "../novedadesAdmin",
        },
        { title: "IPs", icon: "mdi-view-dashboard", to: "../dashboard" },
        { title: "Alertas", icon: "mdi-bell", to: "../alertas" },
        { title: "Usuarios", icon: "mdi-account-group", to: "../usuarios" },
        {
          title: "Periodos y materias",
          icon: "mdi-calendar",
          to: "../periodos",
        },
        { title: "Cursos", icon: "mdi-book", to: "../cursosRoot" },
        // { title: 'Clientes', icon: 'mdi-face-recognition', to: '../clientesAdmin'},
        { title: "Estudiantes", icon: "mdi-account", to: "../estudiantes" },
        { title: "Listas", icon: "mdi-table-of-contents", to: "../listas" },
        {
          title: "Resumen",
          icon: "mdi-file-chart-outline",
          to: "../resumen",
        },

        {
          title: "Caja Negra",
          icon: "mdi-format-line-weight",
          to: "../cajaNegra",
        },
        //Pagos docemtes
      ],
      itemsR: [
        {
          title: "Novedades",
          icon: "mdi-alert-decagram",
          to: "../novedadesAdmin",
        },
        { title: "IPs", icon: "mdi-view-dashboard", to: "../dashboard" },
        { title: "Alertas", icon: "mdi-bell", to: "../alertas" },
        { title: "Usuarios", icon: "mdi-account-group", to: "../usuarios" },
        {
          title: "Periodos y materias",
          icon: "mdi-calendar",
          to: "../periodos",
        },
        { title: "Cursos", icon: "mdi-book", to: "../cursosRoot" },
        {
          title: "Clientes",
          icon: "mdi-face-recognition",
          to: "../clientesAdmin",
        },
        { title: "Ventas", icon: "mdi-account-plus", to: "../clientesRoot" },
        { title: "Estudiantes", icon: "mdi-account", to: "../estudiantes" },
        {
          title: "Pagos estudiantes",
          icon: "mdi-currency-usd",
          to: "../pagos",
        },
        { title: "Listas", icon: "mdi-table-of-contents", to: "../listas" },
        {
          title: "Resumen",
          icon: "mdi-file-chart-outline",
          to: "../resumen",
        },

        {
          title: "Caja Negra",
          icon: "mdi-format-line-weight",
          to: "../cajaNegra",
        },
      ],
      items: [
        // { title: 'Cursos', icon: 'mdi-book', to: '../cursos'},
        {
          title: "Novedades",
          icon: "mdi-alert-decagram",
          to: "../novedadesDoc",
        },
        {
          title: "Estudiantes",
          icon: "mdi-face-recognition",
          to: "../clientes",
        },
        {
          title: "Trabajos",
          icon: "mdi-file-document-outline",
          to: "../trabajosDoc",
        },
        {
          title: "Gestión",
          icon: "mdi-lightbulb-on-outline",
          to: "../gestionDoc",
        },
        {
          title: "Caja Negra",
          icon: "mdi-format-line-weight",
          to: "../cajaNegra",
        },
      ],
      itemsSCli: [
        {
          title: "Novedades",
          icon: "mdi-alert-decagram",
          to: "../novedadesAdmin",
        },
        { title: "IPs", icon: "mdi-view-dashboard", to: "../dashboard" },
        { title: "Alertas", icon: "mdi-bell", to: "../alertas" },
        { title: "Usuarios", icon: "mdi-account-group", to: "../usuarios" },
        {
          title: "Periodos y materias",
          icon: "mdi-calendar",
          to: "../periodos",
        },
        { title: "Cursos", icon: "mdi-book", to: "../cursosRoot" },
        {
          title: "Clientes",
          icon: "mdi-face-recognition",
          to: "../clientesAdmin",
        },
        { title: "Ventas", icon: "mdi-account-plus", to: "../clientesRoot" },
        { title: "Estudiantes", icon: "mdi-account", to: "../estudiantes" },
        {
          title: "Pagos estudiantes",
          icon: "mdi-currency-usd",
          to: "../pagos",
        },
        { title: "Listas", icon: "mdi-table-of-contents", to: "../listas" },
        {
          title: "Resumen",
          icon: "mdi-file-chart-outline",
          to: "../resumen",
        },

        {
          title: "Caja Negra",
          icon: "mdi-format-line-weight",
          to: "../cajaNegra",
        },
      ],
      itemsSC: [
        {
          title: "Novedades",
          icon: "mdi-alert-decagram",
          to: "../novedadesAdmin",
        },
        { title: "IPs", icon: "mdi-view-dashboard", to: "../dashboard" },
        { title: "Alertas", icon: "mdi-bell", to: "../alertas" },
        { title: "Usuarios", icon: "mdi-account-group", to: "../usuarios" },
        {
          title: "Periodos y materias",
          icon: "mdi-calendar",
          to: "../periodos",
        },
        { title: "Cursos", icon: "mdi-book", to: "../cursosRoot" },
        {
          title: "Clientes",
          icon: "mdi-face-recognition",
          to: "../clientesAdmin",
        },
        { title: "Ventas", icon: "mdi-account-plus", to: "../clientesRoot" },
        { title: "Estudiantes", icon: "mdi-account", to: "../estudiantes" },
        {
          title: "Pagos estudiantes",
          icon: "mdi-currency-usd",
          to: "../pagos",
        },
        { title: "Listas", icon: "mdi-table-of-contents", to: "../listas" },
        {
          title: "Resumen",
          icon: "mdi-file-chart-outline",
          to: "../resumen",
        },

        {
          title: "Caja Negra",
          icon: "mdi-format-line-weight",
          to: "../cajaNegra",
        },
      ],
      mini: true,
      user: null,
      name: null,
      dataUser: [],
      dialog: false,
      datosOk: false,
      isAdmin: null,
      notifications: [],
    };
  },
  async created() {
    await data.getAdminLevel().then((e) => {
      this.dataUser = this.$store.state.dataUser = e;
      this.isAdmin = this.$store.state.adminLevel = e.isAdmin;
      if (
        this.dataUser.primerNombre == null ||
        this.dataUser.primerNombre == ""
      ) {
        this.datosOk = false;
      } else {
        this.datosOk = true;
      }
    });
  },

  mounted() {
    this.notificaciones();
  },
  methods: {
    toggleNoti() {
      this.notificaciones();
      this.notifications = [];
    },
    notificaciones() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "deldia");
          axios.post(host + "/notificaciones", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              for (let i = 0; i < data.list.length; i++) {
                const element = data.list[i];
                if (data.list[i]["vista"] == false) {
                  this.notifications.push(element["texto"]);
                }
              }
            } else {
              this.$alert("Error al consultar las notificaciones de hoy");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    out() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        });
    },
    changed(data) {
      if (this.dataUser.primerNombre != null) {
        if (data == false) {
          this.mini = data;
          document.getElementById("contenedor").style.marginLeft = "400px";
        } else {
          this.mini = data;
          document.getElementById("contenedor").style.marginLeft = "56px";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navDrawerDisplay {
  display: inline-block;
}
.textUser {
  margin-left: 10px;
  font-size: 20px;
  color: rgb(22, 52, 135) !important;
}
.margL {
  margin-left: 5px !important;
}
.margR {
  margin-right: -10px !important;
}

.contenedor {
  margin-left: 56px;
}

.version {
  font-size: 10px;
  color: rgb(22, 52, 135);
  font-weight: 400;
}

.name {
  color: rgb(22, 52, 135);
  font-weight: 600;
}

.list_item-version {
  margin: 0 !important;
  padding: 0 !important;
}
</style> 
