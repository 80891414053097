<template>
     <v-card>
          <v-card-title>
            <span class="headline">Tus datos</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="data.primerNombre"
                    label="Primer nombre"
                    required
                    :rules="[rules.required, rules.min, rules.text]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="data.segundoNombre"
                    label="Segundo nombre"
                    :rules="[rules.min, rules.text]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="data.primerApellido"
                    label="Primer apellido"
                    required
                    :rules="[rules.required, rules.min, rules.text]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="data.segundoApellido"
                    label="Segundo apellido"
                    :rules="[rules.min, rules.text]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="second" text @click="$emit('toggle', 'close')">Cerrar</v-btn>
            <v-btn color="second" text @click="savePerfil">Guardar</v-btn>
          </v-card-actions>
        </v-card>
</template>

<script>
import firebase from "firebase";
import {  host } from "../Tools/variables";
import axios from "axios";

export default {
    name: 'NavDrawerEditP',
    props:{
        infoUser: Array
    },
    data() {
        return {
            data : [],
            pNombre: null,
            sNombre: null,
            pApellido: null,
            sApellido: null,
            rules: {
            required: value => {
            !!value || "Campo requerido.";
            },
            min: v => {
            v.length >= 2 || "Nombre invalido";
            },
            text: v => {
            const req = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
            return req.test(v) || "Solo letras";
            }
        },
        }
    },
    mounted() {
        console.log(this.infoUser)
        this.data = this.infoUser
    },
    methods: {
        savePerfil() {
        // rules: {
        //                 required: value => {
        //                      !!value || 'Campo requerido.'},
        //                 min: v => {
        //                     v.length >= 2 || 'Nombre invalido'},
        //                 text: v => {
        //                     const req = /^[a-zA-Z ]*$/
        //                     return req.test(v) || 'Solo letras'
        //                 }
        const req = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
        if (
            this.data.primerNombre != null &&
            this.data.primerApellido != null &&
            req.test(this.data.primerNombre) &&
            req.test(this.data.primerApellido) &&
            this.data.primerNombre.length > 2 &&
            this.data.primerApellido.length > 2
        ) {
            firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then(idToken => {
                // console.log('token' +idToken);
                const fd = new FormData();
                fd.append("token", idToken);
                fd.append("primerNombre", this.data.primerNombre);
                fd.append(
                "segundoNombre",
                this.data.segundoNombre == null ? "" : this.data.segundoNombre
                );
                fd.append("primerApellido", this.data.primerApellido);
                fd.append(
                "segundoApellido",
                this.data.segundoApellido == null ? "" : this.data.segundoApellido
                );
                fd.append("isAdmin", this.isAdmin);
                fd.append("permisoAdmin", false);
                axios.post(host + "/editaUsuario", fd).then(result => {
                let data = result.data.text;
                if (data == "OK") {
                    this.$emit('toggle' , 'refresh')
                } else {
                    this.$alert("Error al guardar la información de tu perfil");
                }
                });
            })
            .catch(function(error) {
                this.$alert("Error " + error.message);
            });
        } else {
            this.$alert(
            "Error en el formato de la información registrada, intentelo nuevamente"
            );
        }
        }
    },
}
</script>

<style>

</style>
