<template>
    <v-content class="v-noti-admin">
        <v-btn v-if="notifications.length > 0" class="btnAlertas" plain small @click="showNotis = !showNotis">
            {{ showNotis == true ? 'Ocultar notis' : 'Ver notis' }}
            <v-icon v-if="showNotis">mdi-arrow-collapse-vertical</v-icon>
            <v-icon v-if="!showNotis">mdi-arrow-expand-vertical</v-icon>
        </v-btn>
        <template v-if="showNotis">
            <v-alert v-for="item in notifications" v-bind:key="item._id" dismissible dense elevation="2" color="blue"
                dark>
                <v-row align="center">
                    <v-col class="grow zeroPadding">
                        <span class="alert-Text-admin">{{ item }}</span>
                    </v-col>
                </v-row>
            </v-alert>
        </template>
    </v-content>
</template>

<script>

export default {
    name: 'NotisAdmin',
    props: { notifications: Array },

    data() {
        return {
            showNotis: true,
        }
    },

}
</script>

<style lang="scss" scoped>
.v-noti-admin {
    margin-top: 0%;
    width: 20%;
    text-align: center;
    position: absolute;
    right: 20%;
    z-index: 999;
    opacity: 0.7;
}


.zeroPadding {
    padding: 0 !important;
}


.btnAlerta {
    text-transform: none !important;
    color: black !important;
    font-size: 12px !important;
    margin-right: 12px !important;
}

.alert-Text-admin {
    font-size: 12px;
}

.btnAlertas {
    text-transform: none !important;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    transition: opacity 1s;
    opacity: 0;
}
</style>
