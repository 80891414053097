<template>
  <v-content class="v-alert-admin">
    <v-btn
      v-if="alertsListShow.length > 0"
      class="btnAlertas"
      plain
      small
      @click="showAlertas = !showAlertas"
    >
      {{ showAlertas == true ? "Ocultar alertas" : "Ver alertas" }}
      <v-icon v-if="showAlertas">mdi-arrow-collapse-vertical</v-icon>
      <v-icon v-if="!showAlertas">mdi-arrow-expand-vertical</v-icon>
    </v-btn>
    <!-- <transition-group name="fade"> -->
    <template v-if="showAlertas">
      <v-alert
        v-show="item.visto == false"
        v-for="item in alertsListShow"
        v-bind:key="item._id"
        dense
        dismissible
        elevation="2"
        color="red"
        dark
      >
        <v-row align="rigth">
          <v-col class="grow zeroPadding">
            <span class="alert-Text-admin">{{ item.comentarios }}</span>
          </v-col>
          <v-col class="shrink zeroPadding">
            <v-btn text class="btnAlerta" x-small @click="marcarVisto(item)"
              >Ver</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </template>
  </v-content>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import data from "../Tools/data";

export default {
  name: "AlertsAdmin",

  data() {
    return {
      showAlertas: true,
      alertsList: [],
      alertsListShow: [],
    };
  },

  watch: {
    alertsList: function () {
      for (var f = 0; f < this.$store.state.alertas.length; f++) {
        var data = this.$store.state.alertas[f];
        if (
          data["estado"] != "cerrado" &&
          (data["comentarios"] != "DPS") | (data["comentarios"] == "LAZARO")
        ) {
          this.alertsListShow.push(data);
        }
      }
    },
  },

  async created() {
    if (this.$store.state.alertas.length == 0) {
      this.alertsList = await data.dataList(
        "/alertas",
        "lista",
        "listar",
        "alertas"
      );
    } else {
      this.alertsList = this.$store.state.alertas;
    }
  },

  methods: {
    marcarVisto(data) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          // console.log('token' +idToken);
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "marcar_visto");
          fd.append("alertaId", data._id);
          axios.post(host + "/alertas", fd).then((result) => {
            // console.log('marcar visto ' + JSON.stringify(result.data));
            let data = result.data.text;
            if (data == "OK") {
              this.alertas();
              this.$router.replace("../alertas");
            } else {
              this.$alert("Error al marcar visto esta alerta");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-alert-admin {
  margin-top: 0%;
  width: 20%;
  text-align: center;
  position: absolute;
  right: 0%;
  z-index: 999;
  opacity: 0.7;
}

.zeroPadding {
  padding: 0 !important;
}

.btnAlerta {
  text-transform: none !important;
  color: black !important;
  font-size: 12px !important;
  margin-right: 12px !important;
}

.alert-Text-admin {
  font-size: 12px;
}
.btnAlertas {
  text-transform: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition: opacity 1s;
  opacity: 0;
}
</style>
