<template>
  <v-container v-if="this.get == false">
    <v-calendar
      ref="calendar"
      v-model="value"
      :weekdays="weekday"
      :type="type"
      :events="events"
      :event-overlap-mode="mode"
      :event-overlap-threshold="30"
      event-color="primary"
      @click:date="popup"
      @click:event="showEvent"
      @change="getEvents"
      locale="ES"
    ></v-calendar>
    <!-- ver la noti -->
    <template v-slot:event>
      <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
    </template>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-card-text>
          <span class="noti">{{ laNoti.name }}</span>
          <v-switch
            color="primary"
            v-model="laNoti.visto"
            label="Marcar en visto"
            @change="marcarVisto(laNoti.id)"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="selectedOpen = false">cerrar</v-btn>
          <v-btn text color="grey" @click="deleteNoti(laNoti.id)"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
    <!-- agregar la noti -->
    <v-dialog v-model="dialog" v-if="dialog" scrollable max-width="30%">
      <v-card>
        <v-card-title>
          <span class="text">Agregar notificación</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col>
              <span class="textNoti">Fecha selecionada: {{ value }}</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="noti.notas"
                label="Notificacíon"
              ></v-text-field>
            </v-col>
            <v-col>
              <span
                >* Recordatorío (puede ser el mismo día de la
                notificación):</span
              >
            </v-col>
            <v-col> </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="close">Cerrar</v-btn>
          <v-btn color="primary" text @click="addNoti">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "Notis",

  data() {
    return {
      get: false,
      dialog: false,
      selectedOpen: false,
      selectedElement: null,
      laNoti: [],
      noti: {
        notas: null,
        dateNoti: null,
      },
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },

  methods: {
    getEvents({ start, end }) {
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      console.log(min, max);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar");
          axios.post(host + "/notificaciones", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              const events = [];
              for (let i = 0; i < data.lista.length; i++) {
                const element = data.lista[i];
                events.push({
                  name: element["texto"],
                  start: this.$moment(element["fecha"]).format("YYYY-MM-DD"),
                  color: "blue",
                  timed: false,
                  visto: element["vista"],
                  id: element["_id"],
                });
              }
              this.events = events;
            } else {
              this.$alert("Error al consultar las notificaciones de hoy");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    getEventColor(event) {
      return event.color;
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    popup() {
      this.dialog = true;
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.laNoti = this.selectedEvent;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    deleteNoti(item) {
      this.get = true;
      // eliminar la noti
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "eliminar");
          fd.append("notiId", item);
          axios.post(host + "/notificaciones", fd).then((result) => {
            if (result.data.text == "OK") {
              this.get = false;
              this.selectedOpen = false;
              this.$emit("toggle", true);
              this.getEvents();
            } else {
              this.$alert(
                "Error al eliminar la notificación, intente nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    marcarVisto(id) {
      this.get = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "marcar_visto");
          fd.append("notiId", id);
          axios.post(host + "/notificaciones", fd).then((result) => {
            if (result.data.text == "OK") {
              this.$emit("toggle", true);
              this.get = false;
              this.selectedOpen = false;
              this.getEvents();
            } else {
              this.$alert(
                "Error al eliminar la notificación, intente nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    addNoti() {
      this.get = true;

      var diff = this.$moment(this.value).diff(
        this.$moment(this.noti.dateNoti).format("YYYY-MM-DD"),
        "days"
      );
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          fd.append("texto", this.noti.notas);
          fd.append("fecha", this.value);
          fd.append("alarma", diff);
          axios.post(host + "/notificaciones", fd).then((result) => {
            console.log(result);
            if (result.data.text == "OK") {
              this.dialog = false;
              this.get = false;
              this.noti.name = null;
              this.noti.dateNoti = null;
              this.$emit("toggle", true);
              this.getEvents();
            } else {
              this.$alert(
                "Error al guardar la notificación, intente nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    close() {
      this.dialog = false;
      this.noti = {
        notas: null,
        dateNoti: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.noti {
  font-size: 20px !important;
  font-weight: 400;
  color: red;
}

.textNoti {
  font-size: 20px;
  color: rgb(22, 52, 135) !important;
}

.text {
  font-size: 25px;
  color: rgb(22, 52, 135) !important;
}
</style>