<template>
  <v-content
          v-show="alertaProfe"
          class="v-alert-docente"
        >
        <v-alert dense elevation="2" color="orange" dark>
        <v-row align="rigth">
            <v-col class="grow zeroPadding">
            <span
                class="alert-Text-docente"
            >{{ textAprofe}}</span>
            </v-col>
        </v-row>
        </v-alert>
    </v-content> 
</template>

<script>
import firebase from "firebase";
import {  host } from "../Tools/variables";
import axios from "axios";


export default {
    name: 'AlertsDoc',
    data() {
        return {
            alertaProfe: false  ,
            textAprofe: "",
        }
    },
    mounted() {
        this.alertas();
    },
    methods: {
    alertas() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(idToken => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "profesor");
              axios.post(host + "/alertas", fd).then(result => {
                this.alertsList = [];
                if (result.data.text != "OK") {
                  this.alertaProfe = true;
                  this.textAprofe = result.data.text + " IP";
                }
              });
            }).catch(function(error) {
            this.$alert("Error " + error.message);
            });
        },
    }

}
</script>

<style lang="scss" scoped>
.v-alert-docente {
  margin-top: 0%;
  width: 15%;
  text-align:center;
  position: absolute;
  bottom: 0%;
  right:0%;
  z-index: 999;
  opacity: 0.7;
}

.alert-Text-docente{
  font-size: 10px;
  color: black;
  font-weight: 900;
}

.zeroPadding{
  padding: 0 !important;
}

</style>
